import { appEnv as getAppEnv } from '../constants/env';
import { metaTagList, type MetaTag } from '../constants/metaTag';
import { SimpleErrorTemplate } from '../templates/SimpleErrorTemplate/SimpleErrorTemplate';

import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { GetStaticProps, NextPage } from 'next';

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
};

const Custom404: NextPage<Props> = ({ appEnv, metaTag }) => (
  <SimpleErrorTemplate
    appEnv={appEnv}
    metaTag={metaTag}
    title="404 Not Found"
  />
);

// eslint-disable-next-line require-await
export const getStaticProps: GetStaticProps = async () => {
  const appEnv = getAppEnv();

  const metaTag = metaTagList().error;

  const props: Props = {
    appEnv,
    metaTag,
  };

  return { props };
};

export default Custom404;
