import {
  AccountTemplate,
  CompleteContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { v4 } from 'uuid';

import { AppHeader } from '../../components/AppHeader';
import { oidcClientId } from '../../constants/env';
import { appUrl } from '../../constants/url';
import { createAuthorizationRequest } from '../../domain/auth';

import type { MetaTag } from '../../constants/metaTag';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { FC } from 'react';

const authorizationRequest = createAuthorizationRequest({
  clientId: oidcClientId(),
  redirectUri: appUrl.top,
  generateUniqueId: v4,
});

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  title: '404 Not Found' | '500 Internal Server Error';
};

export const SimpleErrorTemplate: FC<Props> = ({ appEnv, metaTag, title }) => {
  const breadcrumbElements =
    title === '404 Not Found'
      ? [
          {
            type: 'listItem',
            order: 1,
            text: 'Myページ',
            url: appUrl.top,
          } as const,
          {
            type: 'listItem',
            order: 2,
            text: '404 Not Found',
            url: appUrl.top,
          } as const,
        ]
      : [
          {
            type: 'listItem',
            order: 1,
            text: 'Myページ',
            url: appUrl.top,
          } as const,
          {
            type: 'listItem',
            order: 2,
            text: '500 Internal Server Error',
            url: appUrl.top,
          } as const,
        ];

  const backToLinks =
    title === '404 Not Found'
      ? {
          text: 'お探しのページをみつけることができませんでした。',
          buttonText: 'Myページへ戻る',
          url: appUrl.top,
        }
      : {
          text: '予期せぬエラーが発生しました。お手数ですがしばらく時間が経ってからお試し下さい。',
          buttonText: 'Myページへ戻る',
          url: appUrl.top,
        };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={true} />
      <AccountTemplate
        appEnv={appEnv}
        pageHeader={{ title: 'エラーが発生しました' }}
        breadcrumbElements={breadcrumbElements}
        loading={false}
        isLoggedIn={false}
        authorizationRequest={authorizationRequest}
      >
        <CompleteContents backToLinks={backToLinks} />
      </AccountTemplate>
    </>
  );
};
